import React from 'react';
import './Resume.css'; // optional for styling

const Resume = () => {
  return (
    <div className="resume-container">
      <h1>RAJARAJAN LOGANATHAN</h1>
      <h2>Full Stack Engineer</h2>
      <p>Email: kanchirajan@gmail.com | Phone: 202-413-5990 | Location: Aldie, VA</p>

      <section>
        <h3>Summary</h3>
        <p>
          A results-oriented Full Stack Engineer with 7+ years of experience designing and developing scalable web-based
          applications...
        </p>
      </section>

      <section>
        <h3>Skills</h3>
        <ul>
          <li>Java, Spring Framework, Microservices</li>
          <li>Amazon Web Services (AWS), CI/CD Pipelines</li>
          <li>RESTful APIs, ETL Development, API Design</li>
        </ul>
      </section>

      <section>
        <h3>Experience</h3>
        <h4>Endera Systems LLC | McLean, VA</h4>
        <p>Full Stack Engineer | 02/2017 – Present</p>
        <ul>
          <li>Designed, developed, and implemented Microservices architecture for large-scale enterprise applications...</li>
        </ul>

        <h4>Candescent Technologies | Chennai, India</h4>
        <p>Software Engineer | 04/2014 – 08/2015</p>
        <ul>
          <li>Developed responsive Android applications and led backend services integration using RESTful APIs...</li>
        </ul>

        <h4>BNB Infotech | Chennai, India</h4>
        <p>Software Developer | 04/2012 – 04/2014</p>
        <ul>
          <li>Led POC development for new technologies, resulting in the successful adoption of cloud-based solutions...</li>
        </ul>
      </section>

      <section>
        <h3>Education</h3>
        <p>Hood College | Master of Science in Computer Science | 01/2017</p>
        <p>University of Madras | Bachelor of Science in Computer Science | 04/2012</p>
      </section>
    </div>
  );
};

export default Resume;

